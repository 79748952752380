<template>
  <v-sheet>
    <vuedraggable :list="label.weightInfoList">
      <transition-group class="row">
        <v-col
          cols="12"
          sm="6"
          :md="12 / settings.cols"
          v-for="(weight) in label.weightInfoList"
          :key="weight.index"
          :style="{
            paddingTop: settings.top + 'px',
            paddingRight: settings.right + 'px',
            paddingBottom: settings.bottom + 'px',
            paddingLeft: settings.left + 'px',
            fontSize: settings.fontSize + 'pt',
          }"
          :class="weight.checked ? '' : 'd-print-none'"
        >
          <div style="margin-bottom: 5px">
            <input
              type="checkbox"
              v-model="weight.checked"
              style="margin-right: 2px"
              class="d-print-none"
            />
            <span style="font-weight: bold">Bergsoe Metals Co. Ltd.</span>
            <v-icon
              small
              style="margin-left: 5px; color: blue"
              class="d-print-none"
              @click.stop="deleteWeight(weight.index)"
              >delete
            </v-icon>
          </div>
          <div style="display: flex; flex-direction: row">
            <div
              :style="{ width: settings.qrSize + 'px' }"
              style="position: relative; clear: both"
            >
              <img
                src="../../assets/logo.png"
                :style="{
                  width: settings.qrSize / 5 + 'px',
                  marginTop: (settings.qrSize / 10) * 4 + 'px',
                  marginLeft: (settings.qrSize / 10) * 4 + 'px',
                }"
                style="position: absolute; clear: both"
              />
              <qriously
                :value="
                  label.productName +
                  ';' +
                  label.stockDate +
                  ';' +
                  label.prefix +
                  '-' +
                  label.code +
                  '-' +
                  weight.index +
                  ';' +
                  weight.weight
                "
                :size="displaySettings.qrSize"
              />
            </div>
            <div style="margin-left: 10px">
              {{ label.productName }}<br />
              {{ label.prefix }}-{{ label.code }}-{{ weight.index }}<br />
              {{ label.stockDate }}<br />
              <input
                type="nubmer"
                v-if="weight.editFlag"
                v-model="weight.weight"
                @blur="weightChange(weight.index)"
                style="border-radius: 10px"
              />
              <span v-else>{{ weight.weight }}kg</span>
              <v-icon
                small
                style="margin-left: 5px; color: blue"
                class="d-print-none"
                @click.stop="weightEdit(weight.index)"
                >edit
              </v-icon>
            </div>
          </div>
        </v-col>
      </transition-group>
    </vuedraggable>
  </v-sheet>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    displaySettings: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      label: null,
      settings: {
        left: 50,
        right: 50,
        top: 50,
        bottom: 0,
        qrSize: 100,
        fontSize: 13,
        cols: 2,
      },
    };
  },
  components: { vuedraggable },
  created() {
    this.label = this.value;
    this.settings = this.displaySettings ? this.displaySettings : this.settings;
  },
  methods: {
    weightChange(index) {
      let weightInfoList = this.label.weightInfoList;
      let newWeithInfoList = [];
      let currentIndex = 0;
      for (; currentIndex < weightInfoList.length; currentIndex++) {
        let weight = weightInfoList[currentIndex];
        if (index == weight.index) {
          weight.editFlag = false;
        }
        newWeithInfoList.push(weight);
      }
      this.label.weightInfoList = newWeithInfoList;
    },
    weightEdit(index) {
      let weightInfoList = this.label.weightInfoList;
      let newWeithInfoList = [];
      let currentIndex = 0;
      for (; currentIndex < weightInfoList.length; currentIndex++) {
        let weight = weightInfoList[currentIndex];
        if (index == weight.index) {
          weight.editFlag = true;
        }
        newWeithInfoList.push(weight);
      }
      this.label.weightInfoList = newWeithInfoList;
    },
  },
};
</script>
